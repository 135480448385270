// @flow
import React, {useState, useRef} from 'react';
import {Overlay, Nav} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {BrowseDiv} from './mobileSidebar';
import OrganizationBrowse from './organizationBrowse';

const BrowseSidebar = observer(() => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Nav.Item
        id="open-browse-sidebar"
        data-testid="open-browse-sidebar"
        className="a_openBrowseSidebarButton mobileBrowse mr-2"
        ref={target}
        onClick={() => setShow(!show)}
      >
        <i className="fak fa-globe" />
        <span className="collapseText">Browse</span>
      </Nav.Item>
      <Overlay target={target.current} show={show} placement="bottom">
        {({placement, arrowProps, show: _show, popper, ...props}) => (
          <BrowseDiv {...props}>
            <div className="row flex-column">
              <Nav.Item onClick={() => setShow(!show)} className="closeBrowse">
                <Nav.Link>
                  <i className="fal fa-chevron-left" />
                  <span className="collapseText">Main Menu</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="ml-3">
                <Nav.Link exact as={NavLink} to="/browse/specialty" id="sidebar_specialties">
                  <span className="collapseText ml-1">Specialty</span>
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item className="ml-3">
                <Nav.Link exact as={NavLink} to="/conferences" id="sidebar_conferences">
                  <span className="collapseText ml-1">Conferences</span>
                </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item className="ml-3">
                <Nav.Link exact as={NavLink} to="/courses" id="sidebar_courses">
                  <span className="collapseText ml-1">Courses</span>
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link>
                  <OrganizationBrowse />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="ml-3">
                <Nav.Link exact as={NavLink} id="Experts" to="/experts">
                  <span className="collapseText ml-1">Experts</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="ml-3">
                <Nav.Link exact as={NavLink} id="360VR" to="/360VR">
                  <span className="collapseText ml-1">360VR</span>
                </Nav.Link>
              </Nav.Item>
            </div>
          </BrowseDiv>
        )}
      </Overlay>
    </>
  );
});

export default BrowseSidebar;
